<template>
	<div class="content">
		<Banner :data="banner" :showBreadcrumbs="true"></Banner>
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	import	Banner from '@/components/app/case/Banner'
	import	WithLeftMenu from '@/components/app/WithLeftMenu'
	import 	projectsData from "@/assets/json/projects.json";
	import 	reviewsData from "@/assets/json/reviews.json";

	export default {
		metaInfo: {
			title: 'Кейс интернет-магазина корейской косметики Medar Beauty | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Разработали функциональный и удобный интернет-магазин с эстетическим дизайном. Полная автоматизация всех процессов покупки. Кликните, чтобы посмотреть подробнее.' },
				{ vmid: 'og:title', property: 'og:title', content: 'Кейс интернет-магазина корейской косметики Medar Beauty | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Разработали функциональный и удобный интернет-магазин с эстетическим дизайном. Полная автоматизация всех процессов покупки. Кликните, чтобы посмотреть подробнее.' },
			],
		},
		data: () => ({
			banner: {
				title: 'Medar Beauty',
				desc: 'Интернет-магазин корейской косметики',
				titleIsH1: true,
				image: require(`@/assets/img/medar/banner2.jpg`),
				additionalClass: 'margin',
				bannerType: ' medar'
			},
			withLeftMenuContent: [
				{
					component: 'caseInfo',
					name: 'О проекте',
					nameType: 'big',
					data: {
						desc: "Medar Beauty занимается розничной продажей натуральной корейской косметики напрямую от производителей. Уникальные товары позволяют магазину конкурировать с крупными торговыми сетями.<br><br> На момент обращения к нам у клиента было несколько оффлайн-магазинов и раскрученная группа в Instagram.",
						linkText: 'medarbeauty.ru',
						linkHref: 'http://medarbeauty.ru/',
						date: 'запуск февраль 2020 г.',
						info: [
							{
								title: "Задача",
								desc: "Создать автоматизированный интернет-магазин, в котором покупатели смогут самостоятельно оформить заказ. Помочь бренду наладить онлайн-продажи. Часть трафика мы собираемся направить на сайт из Instagram.",
								author: {
									name: "Айгыль Вахитова",
									desc: "Директор Medar Beauty",
									image: require(`@/assets/img/medar/author1.jpg`)
								}
							},{
								title: "Результат",
								desc: "Интернет-магазин, в котором все процессы протекают без участия собственника. Разгрузили колл-центр, т.к. покупатели с помощью удобной навигации по сайту и фильтров в каталоге сами находят то, что ищут.",
								author: {
									name: "Айрат Вагапов",
									desc: "Менеджер Артрокетс",
									image: require(`@/assets/img/medar/author2.jpg`)
								}
							}
						]
					}
				},{
					component: 'photoScroll',
					name: 'Прототипы',
					nameType: 'big',
					clickable: true,
					data: {
						text: "На этапе прорисовки прототипов страниц будущего сайта продумали, как покупатели будут просматривать каталог, пользоваться фильтрами, корзиной и оформлять заказы.",
						images: [
							{
								image: require(`@/assets/img/medar/prototype/1.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/1_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/2.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/2_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/3.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/3_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/4.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/4_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/5.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/5_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/6.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/6_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/7.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/7_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/8.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/8_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/9.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/9_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/10.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/10_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/11.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/11_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/12.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/12_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/13.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/13_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/14.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/14_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/15.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/15_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/16.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/16_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/17.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/17_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/18.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/18_full.jpg`)
							},{
								image: require(`@/assets/img/medar/prototype/19.jpg`),
								fullImage: require(`@/assets/img/medar/prototype/19_full.jpg`)
							}
						],
						bottomText: "<b>Создание прототипов</b> — самый важный этап создания нового проекта. На этом этапе у заказчика и исполнителя складывается общая картина того, как будет выглядеть будущий проект. Для клиента это несомненно плюс — в конце он получит ожидаемый результат без риска переплатить за переделки."
					}
				},{
					component: 'design',
					name: 'Дизайн',
					nameType: 'big',
					data: {
						title: "Главная страница",
						topText: "Для интернет-магазина желательно с первой страницы эффектно представлять свои товары. Особенно в сфере продаж косметики, где основная аудитория - женщины, у которых восприятие товаров предопределено эстетикой пространства, а выбор зачастую эмоционален. Поэтому, неслучайно, для прорисовки баннеров была выбрана сочная цветовая гамма, которая также подчеркивает направление органической косметики.<br><br> На Главной сделали акцент на популярных товарах, которые можно приобрести в пару кликов. Вывели разводящий блок с основными направлениями магазина. И особое место уделили блоку “Мы в Instagram”",
						bottomText: "Дизайн проработан в трех вариантах, чтобы адаптировать верстку под разные размеры экранов.",
						resolutions: [
							{
								image: require(`@/assets/img/medar/design/1.png`),
								desc: "Desktop - 1440 px.",
								video: require(`@/assets/img/medar/video.mp4`),
							},{
								image: require(`@/assets/img/medar/design/2.png`),
								desc: "Tablet - 768 px."
							},{
								image: require(`@/assets/img/medar/design/3.png`),
								desc: "Mobile - 320 px."
							}
						]
					}
				},{
					component: 'contentBlock',
					name: 'Каталог',
					nameType: 'big',
					data: [
						{
							component: 'imagesTwoCol',
							additionalClass: 'margin2',
							data: {
								title: "Общий список",
								desc: "В каталоге товаров сделан акцент на функциональности. Просмотр товаров можно изменить на вид списком или плитками. Слева от списка располагается удобный фильтр, в котором при выборе параметра моментально без перезагрузки страницы обновляется отфильтрованная выдача. Если по определенному фильтру нет товаров, сайт автоматически скроет этот параметр.",
								photoScrollOnMobile: true,
								leftImages: [
									{
										image: require(`@/assets/img/medar/catalog/1.jpg`)
									}
								],
								rightImages: [
									{
										image: require(`@/assets/img/medar/catalog/2.jpg`)
									},{
										image: require(`@/assets/img/medar/catalog/3.jpg`)
									}
								]
							}
						},{
							component: 'images',
							data: {
								title: "Карточка товара",
								desc: "Детальная страница товара сделана с акцентом на контент. Красивое фото и информативное описание товаров позволяет клиенту принять решение: покупать или искать дальше. Также ниже на странице алгоритмы предлагают сопутствующие товары.",
								images: [
									{
										image: require(`@/assets/img/medar/catalog/4.png`)
									}
								]
							}
						}
					]
				},{
					component: 'contentBlock',
					name: 'Корзина и оформление заказа',
					nameType: 'big',
					data: [
						{
							component: 'text',
							text: 'Корзина',
							additionalClass: "fs20 bold mb6"
						},{
							component: 'text',
							text: 'При наличии товаров в корзине, их количество отображается на иконке в шапке сайта. По клику на корзину отображается предпросмотр покупок. Можно удалить товар  прямо на всплывающем окне или изменить количество единиц - увеличить или уменьшить. Перерасчет суммы происходит моментально без перезагрузки страницы.',
							additionalClass: "margin4"
						},{
							component: 'images',
							additionalClass: 'margin4',
							data: {
								images: [
									{
										image: require(`@/assets/img/medar/cart/4.jpg`)
									}
								]
							}
						},{
							component: 'text',
							text: 'Если даже при отсутствии регистрации, пользователь закроет сайт, выбранный им товар не исчезает из корзины.',
							additionalClass: "margin4"
						},{
							component: 'images',
							additionalClass: 'margin4',
							data: {
								images: [
									{
										image: require(`@/assets/img/medar/cart/1.jpg`)
									}
								]
							}
						},{
							component: 'imagesTwoCol',
							data: {
								title: "Оформление заказа",
								desc: "Процесс оформления заказа мы поделили на шаги, чтобы клиенты не путались в последовательности заполнения данных. Состав корзины клиента всегда у него перед глазами — плашка фиксируется и следует за скроллом.",
								additionalClass: "type2 margin4",
								rightImgFixed: true,
								leftImages: [
									{
										image: require(`@/assets/img/medar/cart/2.jpg`)
									}
								],
								rightImages: [
									{
										image: require(`@/assets/img/medar/cart/3.jpg`),
									}
								]
							}
						},{
							component: 'text',
							text: 'Сделали валидацию данных, чтобы покупателю было  максимально удобно заполнить данные и завершить этап покупки. Проверка на телефон, email, ввод только чисел в числовое поле, букв в символьное поле.'
						}
					]
				},{
					component: 'contentBlock',
					name: 'Личный кабинет',
					nameType: 'big',
					data: [
						{
							component: 'imagesTwoCol',
							clickable: true,
							data: {
								leftBottomText: "Покупатель может просмотреть свою историю покупок и отслеживать заказ в личном кабинете.",
								photoScrollOnMobile: true,
								leftImages: [
									{
										image: require(`@/assets/img/medar/personal/1.jpg`),
										fullImage: require(`@/assets/img/medar/personal/1_full.jpg`)
									}
								],
								rightImages: [
									{
										image: require(`@/assets/img/medar/personal/2.jpg`),
										fullImage: require(`@/assets/img/medar/personal/2_full.jpg`)
									},{
										image: require(`@/assets/img/medar/personal/3.jpg`),
										fullImage: require(`@/assets/img/medar/personal/3_full.jpg`)
									}
								]
							}
						}
					]
				},{
					component: 'contentBlock',
					name: 'Еще немного деталей',
					nameType: 'big',
					data: [
						{
							component: 'text',
							additionalClass: 'margin4',
							text: "Был переработан логотип компании. Предложили клиенту несколько вариантов."
						},{
							component: 'images',
							additionalClass: 'zi1 posr',
							data: {
								images: [
									{
										image: require(`@/assets/img/medar/brandbook.png`)
									}
								]
							}
						},{
							component: 'medarPreloader',
							data: {
								title: "Добавили на сайт оригинальный прелоудер",
								desc: "Прелоудер используется везде, где пользователю приходится немного ждать: при загрузке сайта, серфингу по страницам и там, где происходят сложные вычисления и нужно время для завершения процесса."
							}
						}
					]
				},{
					component: 'contentBlock',
					name: 'Интеграции',
					nameType: 'big',
					data: [
						{
							component: 'images',
							additionalClass: 'margin2',
							data: {
								title: "Админка",
								desc: "У CMS Bitrix простой и интуитивно понятный административный раздел. Поэтому редактор сайта может создать новую страницу, отредактировать шаблоны email-писем, обработать новые заказы и многое другое без участия программистов.",
								images: [
									{
										image: require(`@/assets/img/medar/admin.jpg`)
									}
								]
							}
						},{
							component: 'images',
							additionalClass: 'margin2',
							data: {
								title: "Интеграция с 1С",
								desc: "Номенклатура из 1С: Предприятие выгружается на сайт в виде каталога. Синхронизация настроена на автообновление каждые пять минут. После оформления заказа на сайте, в 1С моментально появляется новый контрагент с его составом заказа.",
								images: [
									{
										image: require(`@/assets/img/medar/1c.jpg`)
									}
								]
							}
						},{
							component: 'textWithImages',
							additionalClass: 'margin2',
							data: {
								title: "Доставка",
								desc: "Сайт определяет город пользователя по IP и предлагает возможные варианты доставки. Для жителей Казани можно выбрать точку самовывоза — оффлайн-магазины Medar Beauty.",
								imageCol: 1,
								images: [
									{
										image: require(`@/assets/img/medar/delivery.jpg`)
									}
								]
							}
						},{
							component: 'textWithImages',
							additionalClass: 'margin2',
							data: {
								desc: "Для автоматического подсчета стоимости доставки за пределы Казани мы выбрали готовый модуль из <a class='redLink' target='_blank' href='https://marketplace.1c-bitrix.ru/'>Маркетплейс</a>.",
								imageCol: 1,
								images: [
									{
										image: require(`@/assets/img/medar/sum.jpg`)
									}
								]
							}
						},{
							component: 'textWithImages',
							data: {
								title: "Оплата",
								desc: "Магазин принимает оплаты банковской картой с помощью модуля «Сбербанка».",
								imageCol: 2,
								images: [
									{
										image: require(`@/assets/img/medar/pay2.png`)
									},{
										image: require(`@/assets/img/medar/pay1.png`)
									}
								]
							}
						}
					]
				},{
					component: 'reviews',
					name: 'Отзыв клиента',
					nameType: 'big',
					data: reviewsData,
					project: 'medar'
				},{
					component: 'projects',
					name: 'Другие проекты',
					nameType: 'big',
					quantity: 3,
					quantityTable: 3,
					quantityMobile: 3,
					//type: "Разработка сайтов",
					smallElements: true,
					data: projectsData
				}
			]
		}),
		name: 'medar',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			WithLeftMenu,
			Banner
		}
	}
</script>
